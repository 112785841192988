import React, { useEffect } from "react"
import { globalHistory } from "@reach/router"

import GameCollection from "~/models/game-collection"
import Game from "~/models/game"

import TitledSection from "~/ui/layout/titled-section"
import GameCard from "~/ui/components/game/card"

import useArcade from "~/hooks/useArcade"

type ButtonProps = {
  label: string
  link: string
}

type Props = {
  title?: string
  description?: string
  collection: GameCollection
  button?: ButtonProps
  className?: string
  background?: string
}

GameToplistSection.defaultProps = {
  background: "white",
}

function GameToplistSection({
  title,
  description,
  background,
  collection,
  className,
}: Props) {
  const { handleDeactivation } = useArcade()

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (["PUSH", "POP"].indexOf(action) != -1) {
        handleDeactivation()
      }
    })
  }, [])

  return (
    <TitledSection
      title={title}
      description={description}
      background={background as any}
      className={className}
    >
      <div>
        {collection.games.map((game: Game, index: number) => {
          return <GameCard key={index} game={game} className="mb-10" />
        })}
      </div>
    </TitledSection>
  )
}

export default GameToplistSection
