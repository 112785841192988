import React, { useEffect, useState } from "react"
import cx from "classnames"
import { debounce } from "lodash"

import Game from "~/models/game"
import Select from "~/ui/elements/form/select"
import Search from "~/ui/elements/form/search"
import ArcadeCard from "~/ui/components/game/arcade"
import Placeholder from "~/ui/components/game/placeholder"
import PrimaryFilter from "~/ui/components/generic/filter"
import Arrow from "~/ui/identity/icon/arrow"
import DoubleArrow from "~/ui/identity/icon/double-arrow"
import Exclamation from "~/ui/identity/icon/exclamation"
import Filter from "~/ui/identity/icon/filter"
import Close from "~/ui/identity/icon/close"
import ActiveFilter from "~/ui/components/generic/active-filter"

import useArcade from "~/hooks/useArcade"

import arcadeConditions from "./conditions"

function Arcade() {
  const {
    loading,
    games,
    meta,
    action,
    handleActivation,
    handleFilter,
    handleSearch,
    conditions,
    handlePagination,
    handleLoad,
  } = useArcade()

  const [filtersOpened, setFiltersOpened] = useState(false)
  const [provider, setProvider] = useState("all")
  const filterContainer = React.useRef(null)

  useEffect(() => {
    handleLoad()
  }, [])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    closeFilters()
    e.preventDefault()

    handleFilter({
      ...conditions,
      providerIds: provider == "all" ? [] : [parseInt(provider)],
    })
  }

  const toggleFilters = () => {
    if (filtersOpened) {
      closeFilters()
    } else {
      openFilters()
    }
  }

  const onClickOutside = (e: any) => {
    if (
      filterContainer.current &&
      !(filterContainer.current as any).contains(e.target)
    ) {
      closeFilters()
    }
  }

  const openFilters = () => {
    setFiltersOpened(true)
    document.addEventListener("mousedown", onClickOutside)
  }

  const closeFilters = () => {
    setFiltersOpened(false)
    document.removeEventListener("mousedown", onClickOutside)
  }

  const onChangePage = (page: number) => {
    handlePagination(
      action,
      {
        ...conditions,
      },
      page
    )
  }

  const onChangeCondition = (newConditions: any) => {
    handleFilter({
      ...conditions,
      ...newConditions,
    })
  }

  const onSearch = (newConditions: any) => {
    if (newConditions.name.length == 0) {
      handleLoad()
      return
    }

    handleSearch({
      ...conditions,
      ...newConditions,
    })
  }

  const onClick = (e: React.MouseEvent<HTMLElement>, game: Game) => {
    e.preventDefault()
    handleActivation(game)
  }

  return (
    <React.Fragment>
      <section className="overflow-scroll scrollbar-hidden  max-w-screen-xl mx-auto pb-6 pt-10 md:pt-16 px-4 md:px-10 xl:px-20">
        <div className="min-w-[640px] p-1 bg-font-1 rounded-lg flex justify-between">
          <PrimaryFilter
            onClick={() =>
              onChangeCondition({
                ...conditions,
                sortingFields: { search_volume: "desc" },
                gameTypes: [],
              })
            }
            image="filter-popular"
            alt="Free Casino Games"
            active={
              conditions.sortingFields && conditions.sortingFields.search_volume
            }
            label="Most Popular"
          />
          <PrimaryFilter
            onClick={() =>
              onChangeCondition({
                ...conditions,
                sortingFields: { id: "asc" },
                gameTypes: [],
              })
            }
            image="filter-recommended"
            alt="Free Casino Games"
            active={conditions.sortingFields && conditions.sortingFields.id}
            label="Recommended"
          />
          <PrimaryFilter
            onClick={() =>
              onChangeCondition({
                ...conditions,
                sortingFields: {},
                gameTypes: ["SLOTS"],
              })
            }
            image="filter-slots"
            alt="Slots"
            active={conditions.gameTypes[0] == "SLOTS"}
            label="Slots"
          />
          <PrimaryFilter
            onClick={() =>
              onChangeCondition({
                ...conditions,
                sortingFields: {},
                gameTypes: ["ROULETTE"],
              })
            }
            image="filter-roulette"
            alt="Roulette"
            active={conditions.gameTypes[0] == "ROULETTE"}
            label="Roulette"
          />
        </div>
      </section>

      <section className="max-w-screen-xl mx-auto pb-10 md:pb-16 px-4 md:px-10 xl:px-20">
        <div>
          <div className="flex flex-col xl:flex-row mb-4 lg:mb-6 gap-4 relative">
            <Search
              onChange={debounce(e => {
                e.preventDefault()
                onSearch({
                  name: (e.target as any).value,
                })
              })}
              placeholder="Search"
              className="flex-1"
              onClear={() => {
                onChangeCondition({
                  gameTypes: [],
                  name: "",
                  providerIds: [],
                  sortingFields: { search_volume: "desc" },
                })
              }}
            />

            <div className="flex gap-4">
              <div ref={filterContainer} className="flex-1 relative">
                <button
                  className="xl:w-[270px] flex w-full items-center py-3 px-6 gap-1 rounded-md border border-gray-30 justify-between"
                  onClick={_ => toggleFilters()}
                >
                  <span className="text-sm text-font-1">Filters</span>
                  <Filter width={14} />
                </button>

                {filtersOpened && (
                  <div className="min-w-[320px] fixed bottom-0 top-0 left-0 right-0 md:absolute md:bottom-auto md:left-auto md:top-20 md:right-0 bg-white z-50 shadow-card p-6 rounded-2xl">
                    <div className="mb-4 flex justify-end">
                      <button onClick={closeFilters}>
                        <Close width={24} />
                      </button>
                    </div>
                    <form onSubmit={onSubmit}>
                      <div className="flex flex-col gap-8 mb-8">
                        {/* <Select
                          label="Game Category"
                          name="game"
                          value={game}
                          onChange={e => setGame(e.target.value)}
                        >
                          <option value="all">Select Game Category</option>
                          {Object.entries(arcadeConditions.gameTypes).map(
                            (type, index) => {
                              return (
                                <option key={index} value={type[0]}>
                                  {type[1]}
                                </option>
                              )
                            }
                          )}
                        </Select> */}
                        <Select
                          label="Game Providers"
                          name="provider"
                          value={provider}
                          onChange={e => setProvider(e.target.value)}
                        >
                          <option value="all">Select Game Provider</option>
                          {Object.entries(arcadeConditions.providers).map(
                            (provider, index) => {
                              return (
                                <option key={index} value={provider[0]}>
                                  {provider[1]}
                                </option>
                              )
                            }
                          )}
                        </Select>
                      </div>

                      <div className="text-center">
                        <input
                          type="submit"
                          value="Add Filters"
                          className="w-full cursor-pointer inline-block py-4 px-12 text-base leading-none text-center lg:text-lg rounded-lg bg-blue-600 text-white hover:bg-blue-700"
                        />
                      </div>
                    </form>
                  </div>
                )}
              </div>

              <Select
                name="sort"
                className="xl:shrink-0 xl:w-[270px] flex-1"
                onChange={e => {
                  const criteria = JSON.parse(e.target.value)
                  onChangeCondition({
                    sortingFields: { [criteria.field]: criteria.order },
                  })
                }}
              >
                <option value='{"field":"id","order":"asc"}'>Sort</option>
                <option value='{"field":"name","order":"asc"}'>A - Z</option>
                <option value='{"field":"search_volume","order":"desc"}'>
                  Most Popular
                </option>
                <option value='{"field":"release_date","order":"desc"}'>
                  Newly Added
                </option>
              </Select>
            </div>
          </div>

          <div className="cf">
            {conditions.providerIds.map((id: number) => (
              <ActiveFilter
                key={id}
                onClick={() => {
                  onChangeCondition({
                    ...conditions,
                    providerIds: [],
                  })
                }}
                label={arcadeConditions.providers[id]}
              />
            ))}
          </div>

          <h2 className="mt-4 mb-12 lg:mb-14 text-base text-font-3 font-medium">
            {`${meta.rowsFound} games found`}
          </h2>
        </div>

        <div>
          {loading ? (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-4">
              {Array.from({ length: 12 }).map((_, index) => {
                return <Placeholder key={index} />
              })}
            </div>
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-4">
              {games.map((game: Game) => {
                return (
                  <ArcadeCard
                    key={game.id}
                    game={game}
                    onClick={e => onClick(e, game)}
                  />
                )
              })}
            </div>
          )}

          {meta.rowsFound == 0 && !loading && (
            <div className="flex items-center gap-4 justify-center py-10">
              <Exclamation width={40} />
              <span className="text-xl lg:text-4xl font-medium text-font-1">
                No results were found
              </span>
            </div>
          )}
        </div>

        {meta.pagesCount > 0 && (
          <div className="flex lg:gap-4 justify-center mt-14">
            <button className="hidden lg:block" onClick={_ => onChangePage(1)}>
              <DoubleArrow width={20} className="rotate-180" />
            </button>

            <button
              onClick={_ => onChangePage(meta.pageNumber - 1)}
              className={cx({
                "opacity-20 pointer-events-none": meta.pageNumber == 1,
              })}
            >
              <Arrow width={20} className="rotate-90" />
            </button>

            {Array.from({ length: meta.pagesCount })
              .filter((_, index: number) => index < 5)
              .map((_, index: number) => {
                const idx = Math.ceil(meta.pageNumber / 5) * 5 - 4 + index
                return (
                  <button
                    key={idx}
                    onClick={_ => onChangePage(idx)}
                    className={cx(
                      "rounded-lg  font-medium px-2 min-w-[40px] h-10 lg:min-w-[56px] lg:h-14 text-center",
                      {
                        "bg-blue-600 text-white": idx == meta.pageNumber,
                        "text-font-1": idx != meta.pageNumber,
                      }
                    )}
                  >
                    {idx}
                  </button>
                )
              })}

            {meta.pagesCount > 5 && meta.pageNumber < meta.pagesCount - 5 && (
              <button
                onClick={_ =>
                  onChangePage((Math.ceil(meta.pageNumber / 5) + 1) * 5 - 4)
                }
                className="text-sm sm:text-base lg:text-2xl rounded-lg text-font-1 font-medium min-w-[40px] h-10 lg:min-w-[56px] lg:h-14 text-center"
              >
                ...
              </button>
            )}

            <button
              onClick={_ => onChangePage(meta.pageNumber + 1)}
              className={cx({
                "opacity-20 pointer-events-none":
                  meta.pageNumber == meta.pagesCount,
              })}
            >
              <Arrow width={20} className="-rotate-90" />
            </button>

            <button
              className="hidden lg:block"
              onClick={_ => onChangePage(meta.pagesCount)}
            >
              <DoubleArrow width={20} />
            </button>
          </div>
        )}
      </section>
    </React.Fragment>
  )
}

export default Arcade
