import React from "react"
import cx from "classnames"

type Props = {
  className?: string
}

function Placeholder({ className }: Props) {
  return (
    <div
      className={cx(
        "placeholder-loader flex flex-col  rounded-lg overflow-hidden",
        className
      )}
    >
      <div className="w-full h-[168px]"></div>
      <div className="w-full flex justify-center py-4">
        <div className="block flex-shrink-0 z-10 h-[24px] sm:h-[36px]"></div>
      </div>
    </div>
  )
}

export default Placeholder
