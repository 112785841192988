import React from "react"

type Props = {
  className?: string
  width?: number
  style: string
  color: string
}

Star.defaultProps = {
  style: "fill",
  color: "#FEC60D",
}

function Star(props: Props) {
  return (
    <svg
      width={props.width}
      height={props.width}
      className={props.className}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {props.style == "half" && (
        <React.Fragment>
          <g clipPath="url(#clip0_4594_13299)">
            <path
              d="M10.4999 1.61823L13.1414 6.97103L13.2577 7.20678L13.5179 7.24458L19.4255 8.10292L15.151 12.2695L14.9628 12.453L15.0072 12.7121L16.016 18.5956L10.7327 15.8178L10.5 15.6955L10.2673 15.8178L4.98368 18.5956L5.99282 12.7121L6.03725 12.453L5.84902 12.2695L1.57449 8.10291L7.48169 7.24458L7.74182 7.20678L7.85816 6.97106L10.4999 1.61823Z"
              stroke={props.color}
            />
            <g clipPath="url(#clip1_4594_13299)">
              <path
                d="M10.5 0.488403L10.589 3.749L10.5 7.75379V12.6276V13.3296V16.2604L4.31961 19.5096L5.50001 12.6276L0.5 7.75379L7.40979 6.74977L10.5 0.488403Z"
                fill={props.color}
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_4594_13299">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
            <clipPath id="clip1_4594_13299">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </React.Fragment>
      )}

      {props.style == "fill" && (
        <React.Fragment>
          <g clipPath="url(#clip0_4594_13297)">
            <path
              d="M10.5 0.488403L13.5898 6.74977L20.5 7.75379L15.5 12.6276L16.68 19.5096L10.5 16.2604L4.31961 19.5096L5.50001 12.6276L0.5 7.75379L7.40979 6.74977L10.5 0.488403Z"
              fill={props.color}
            />
          </g>
          <defs>
            <clipPath id="clip0_4594_13297">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </React.Fragment>
      )}

      {props.style == "outline" && (
        <React.Fragment>
          <g clipPath="url(#clip0_4594_13299)">
            <path
              d="M10.4999 1.61823L13.1414 6.97103L13.2577 7.20678L13.5179 7.24458L19.4255 8.10292L15.151 12.2695L14.9628 12.453L15.0072 12.7121L16.016 18.5956L10.7327 15.8178L10.5 15.6955L10.2673 15.8178L4.98368 18.5956L5.99282 12.7121L6.03725 12.453L5.84902 12.2695L1.57449 8.10291L7.48169 7.24458L7.74182 7.20678L7.85816 6.97106L10.4999 1.61823Z"
              stroke={props.color}
            />
          </g>
          <defs>
            <clipPath id="clip0_4594_13299">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </React.Fragment>
      )}
    </svg>
  )
}

export default Star
