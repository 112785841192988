import React from "react"
import cx from "classnames"

import TitledSection from "~/ui/layout/titled-section"
import Check from "~/ui/identity/icon/check"
import Cross from "~/ui/identity/icon/cross"

type Props = {
  title?: string
  description?: string
  className?: string
  header: string[]
  body: string[][]
}

function Table({ title, description, header, body, className }: Props) {
  return (
    <TitledSection
      title={title}
      description={description}
      background="white"
      className={className}
    >
      <table className="shadow-card w-full rounded-3xl overflow-hidden text-left border border-gray-20">
        <thead>
          <tr>
            {header.map((h, index) => {
              return (
                <th
                  key={index}
                  className="py-4 px-5 lg:px-10 text-xs lg:text-base text-font-1 bg-yellow-200 text-center first:text-left"
                >
                  {h}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {body.map((items, index) => {
            return (
              <tr key={index} className={cx({ "bg-gray-10": index % 2 != 0 })}>
                {items.map((item, index) => {
                  return (
                    <td
                      key={index}
                      className="py-4 px-5 lg:px-10 border-b border-gray-200 text-xs lg:text-base text-font-1"
                    >
                      {item == "Yes" && (
                        <Check
                          width={24}
                          className="mx-auto w-[24px] lg:w-[24px]"
                        />
                      )}
                      {item == "No" && (
                        <Cross
                          width={24}
                          className="mx-auto w-[24px] lg:w-[24px]"
                        />
                      )}
                      {item != "Yes" && item != "No" && item}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </TitledSection>
  )
}

export default Table
