import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import cx from "classnames"

import useArcade from "~/hooks/useArcade"
import sanitize from "~/helpers/sanitize"
import Button from "~/ui/elements/button/button"
import GameCollection from "~/models/game-collection"

import { ContentfulGameCollection } from "../../../../types/graphql-types"

type Props = {
  className?: string
  title?: string
  description?: string
}

function RandomGame({ className, title, description }: Props) {
  const { contentfulGameCollection } = useStaticQuery(
    graphql`
      query RandomGame {
        contentfulGameCollection(type: { eq: "toplist" }) {
          ...GameCollectionFields
        }
      }
    `
  )
  const collection = new GameCollection(
    contentfulGameCollection as ContentfulGameCollection
  )

  const { games, handleActivation } = useArcade()

  const random = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min)) + min

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    handleActivation(
      games.length > 0
        ? games[random(0, games.length - 1)]
        : collection.games[0]
    )
  }

  return (
    <section
      className={cx("overflow-hidden py-12 md:py-20 bg-[#211E29]", className)}
    >
      <div className="relative max-w-screen-xl mx-auto px-4 md:px-10 xl:px-20">
        {title && (
          <div className="relative mb-8 z-10 lg:px-20 xl:px-52">
            <h2
              className="text-2xl font-bold text-blue-600 lg:text-4xl text-center tracking-[-0.025em]"
              dangerouslySetInnerHTML={sanitize(title)}
            ></h2>

            {description && (
              <p
                className="mt-3 text-sm text-white lg:text-lg text-center"
                dangerouslySetInnerHTML={sanitize(description)}
              ></p>
            )}
          </div>
        )}

        <div className="relative z-10 text-center">
          <Button type="primary" onClick={onClick}>
            Play random game
          </Button>
        </div>
      </div>
    </section>
  )
}

export default RandomGame
