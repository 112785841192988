import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
  stroke?: string
}

function Exclamation(props: Props) {
  return (
    <svg
      width={props.width}
      className={props.className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4285 23.7271C21.4285 24.2375 21.1564 24.7092 20.7144 24.9644C20.2724 25.2196 19.7275 25.2196 19.2856 24.9644C18.8436 24.7092 18.5715 24.2375 18.5715 23.7271V12.3813C18.5715 11.8706 18.8436 11.3991 19.2856 11.1439C19.7276 10.8887 20.2725 10.8887 20.7144 11.1439C21.1564 11.3991 21.4285 11.8706 21.4285 12.3813V23.7271ZM20 29.5245C19.2114 29.5245 18.5715 28.9682 18.5715 28.2824C18.5715 27.5958 19.2113 27.0395 20 27.0395C20.7887 27.0395 21.4285 27.5958 21.4285 28.2824C21.4285 28.9682 20.7887 29.5245 20 29.5245ZM20 0C8.95424 0 0 8.95424 0 20C0 31.0458 8.95424 40 20 40C31.0458 40 40 31.0458 40 20C40 8.95424 31.0458 0 20 0Z"
        fill="#350025"
      />
    </svg>
  )
}

export default Exclamation
