import React from "react"
import cx from "classnames"

import Game from "~/models/game"
import Image from "~/ui/elements/image/image"
import Button from "~/ui/elements/button/button"
import TrendingUp from "~/ui/identity/icon/trending-up"
import StarIcon from "~/ui/identity/icon/star"

import useArcade from "~/hooks/useArcade"

type Props = {
  game: Game
  className?: string
}

function Head({ game, className }: any) {
  let asset = game.assets?.find(
    (asset: any) => asset.tags?.indexOf("logo") != -1
  )

  return (
    <div className={cx("w-full sm:w-[120px]", className)}>
      <div className="rounded-lg overflow-hidden relative z-10">
        <Image
          className="block mb-2 sm:w-[120px] sm:h-[120px] rounded-lg"
          gatsbyImageData={asset?.localFile?.childImageSharp?.gatsbyImageData}
          alt={game.name}
          zoom
        />
      </div>
      <div className="text-center sm:text-left text-font-1 font-medium">
        {game.name}
      </div>
    </div>
  )
}

function Provider({ game, className }: any) {
  return (
    <div
      className={cx("flex flex-col justify-center md:justify-start", className)}
    >
      <div className="mb-4 text-center lg:text-left text-font-2">Provider</div>
      <img
        src={`/images/provider/${game.provider.normalisedName}.svg`}
        alt=""
        className="block flex-shrink-0 z-10 h-[31px]"
        decoding="async"
      />
    </div>
  )
}

function Payout({ game, className }: any) {
  return (
    <div className={className}>
      <div className="mb-4 text-font-2">Payout Percentage</div>
      <div className="py-2 px-4 border border-blue-600 rounded-full bg-white inline-flex items-center">
        <TrendingUp width={24} fill="#5E6EFF" className="mr-2" />
        <span className="text-lg font-medium text-blue-600 leading-none">
          {game.RTP ? `${game.RTP}%` : "NA"}
        </span>
      </div>
    </div>
  )
}

function MinBet({ game, className }: any) {
  return (
    <div className={className}>
      <div className="mb-4 text-font-2">Min. Bet</div>
      <div className="text-2xl font-medium text-font-1 leading-none">
        {game.minBet ? `\$${game.minBet}` : "NA"}
      </div>
    </div>
  )
}

function MaxBet({ game, className }: any) {
  return (
    <div className={className}>
      <div className="mb-4 text-font-2">Max. Bet</div>
      <div className="text-2xl font-medium text-font-1 leading-none">
        {game.maxBet ? `\$${game.maxBet}` : "NA"}
      </div>
    </div>
  )
}

function Valuation({ className }: any) {
  return (
    <div className={className}>
      <div className="mb-4 text-font-2">Valuation</div>

      <div className="inline-flex">
        <StarIcon width={20} className="mr-1" style="fill" />
        <div className="flex gap-1">
          <span className="text-2xl font-medium text-font-1 leading-none">
            5
          </span>
          <span className="text-2xl font-regular text-purple-300 leading-none">
            /
          </span>
          <span className="text-2xl font-regular text-purple-300 leading-none">
            5
          </span>
        </div>
      </div>
    </div>
  )
}

function Divider() {
  return <div className="w-[1px] h-[88px] bg-purple-100"></div>
}

function GameCard({ game, className }: Props) {
  const { handleActivation } = useArcade()

  function onClickPlayFree(e: React.MouseEvent<HTMLElement>, game: Game) {
    e.preventDefault()
    handleActivation(game)
  }

  return (
    <div className={cx("shadow-card px-6 rounded-xl bg-white", className)}>
      <div className="hidden xl:flex justify-between py-6">
        <Head game={game} />
        <Provider game={game} />
        <Divider />
        <Payout game={game} />
        <Divider />
        <MinBet game={game} />
        <Divider />
        <MaxBet game={game} />
        <Divider />
        <Valuation />
        <div className="flex flex-col gap-4">
          <Button type="primary" onClick={e => onClickPlayFree(e, game)}>
            Play Free
          </Button>
          <a
            href="/links/22bet/"
            target="_blank"
            rel="noopener nofollow"
            className="font-medium inline-block py-3 px-9 leading-none text-center text-lg rounded-lg border-2 border-blue-600 bg-white text-blue-600 hover:bg-blue-600 hover:text-white"
          >
            Play Real
          </a>
        </div>
      </div>

      <div className="hidden lg:block xl:hidden py-6">
        <div className="flex mb-4">
          <Head game={game} />
          <div className="flex justify-evenly w-full">
            <Provider game={game} />
            <Divider />
            <Payout game={game} />
            <Divider />
            <MinBet game={game} />
            <Divider />
            <MaxBet game={game} />
            <Divider />
            <Valuation />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <Button type="primary" onClick={e => onClickPlayFree(e, game)}>
            Play Free
          </Button>
          <a
            href="/links/22bet/"
            target="_blank"
            rel="noopener nofollow"
            className="font-medium inline-block py-3 px-9 leading-none text-center text-lg rounded-lg border-2 border-blue-600 bg-white text-blue-600 hover:bg-blue-600 hover:text-white"
          >
            Play Real
          </a>
        </div>
      </div>

      <div className="hidden sm:block lg:hidden py-6">
        <div className="flex mb-4">
          <Head game={game} />
          <div className="flex justify-evenly w-full">
            <Payout game={game} />
            <Divider />
            <MinBet game={game} />
            <Divider />
            <MaxBet game={game} />
            <Divider />
            <Valuation />
          </div>
        </div>
        <Provider game={game} className="mb-4" />
        <div className="flex flex-col gap-4">
          <Button type="primary" onClick={e => onClickPlayFree(e, game)}>
            Play Free
          </Button>
          <a
            href="/links/22bet/"
            target="_blank"
            rel="noopener nofollow"
            className="font-medium inline-block py-3 px-9 leading-none text-center text-lg rounded-lg border-2 border-blue-600 bg-white text-blue-600 hover:bg-blue-600 hover:text-white"
          >
            Play Real
          </a>
        </div>
      </div>

      <div className="block sm:hidden py-6">
        <Head game={game} className="mb-4" />

        <div className="grid grid-cols-2 mb-4">
          <Payout game={game} className="flex flex-col items-center " />
          <MinBet game={game} className="flex flex-col items-center " />
          <MaxBet game={game} className="flex flex-col items-center" />
          <Valuation className="flex flex-col items-center" />
        </div>

        <Provider game={game} className="mb-4" />
        <div className="flex flex-col gap-4">
          <Button type="primary" onClick={e => onClickPlayFree(e, game)}>
            Play Free
          </Button>
          <a
            href="/links/22bet/"
            target="_blank"
            rel="noopener nofollow"
            className="font-medium inline-block py-3 px-9 leading-none text-center text-lg rounded-lg border-2 border-blue-600 bg-white text-blue-600 hover:bg-blue-600 hover:text-white"
          >
            Play Real
          </a>
        </div>
      </div>
    </div>
  )
}

export default GameCard
