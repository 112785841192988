import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

function Cross(props: Props) {
  return (
    <svg
      width={props.width}
      className={props.className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#FFEDEE" />
      <path
        d="M5.73527 11.4802C5.49013 11.7254 5.09292 11.7253 4.84792 11.4802C4.60278 11.235 4.60278 10.8378 4.84792 10.5928L10.5927 4.84803C10.8378 4.60274 11.235 4.60274 11.48 4.84803C11.7255 5.09317 11.7252 5.49038 11.48 5.73538L5.73527 11.4802Z"
        fill="#FF4757"
      />
      <path
        d="M4.84792 5.73538C4.60278 5.49038 4.60278 5.09303 4.84792 4.84803C5.09307 4.60274 5.49027 4.60274 5.73527 4.84803L11.48 10.5928C11.7255 10.8379 11.7255 11.2351 11.48 11.4802C11.2349 11.7254 10.8377 11.7253 10.5927 11.4802L4.84792 5.73538Z"
        fill="#FF4757"
      />
    </svg>
  )
}

export default Cross
