import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
  stroke?: string
}

DoubleArrow.defaultProps = {
  stroke: "#350025",
}

function DoubleArrow(props: Props) {
  return (
    <svg
      width={props.width}
      className={props.className}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3325 6.66537L26.6659 19.9987L13.3325 33.332"
        stroke="#350025"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6665 6.66537L39.9998 19.9987L26.6665 33.332"
        stroke="#350025"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DoubleArrow
