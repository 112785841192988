const arcadeConditions = {
  gameTypes: {
    ARCADE: "Arcade",
    BACCARAT: "Baccarat",
    BINGO: "Bingo",
    BLACKJACK: "Blackjack",
    CARD_GAMES: "Card Games",
    CRAPS: "Craps",
    GAME_SHOWS: "Game Shows",
    KENO: "Keno",
    OTHER: "Other",
    ROULETTE: "Roulette",
    SCRATCH_CARDS: "Scratch Cards",
    SLINGO: "Slingo",
    SLOTS: "Slots",
    TABLE_GAMES: "Table Games",
    VIDEO_POKER: "Video Poker",
    VIRTUAL_SPORTS: "Virtual Sports",
  },
  providers: {
    1: "Microgaming",
    2: "NetEnt",
    3: "Playtech",
    4: "IGT",
    5: "QuickSpin",
    6: "Leander Games",
    7: "Bally Technologies",
    8: "1x2 Gaming",
    9: "iSoftBet",
    10: "Play'n GO",
    11: "NYX Interactive",
    12: "Viaden Gaming",
    15: "Aristocrat Gaming",
    16: "NextGen Gaming",
    17: "ELK Studios",
    18: "Betsoft Gaming",
    19: "Rival",
    20: "Genesis Gaming",
    21: "WMS",
    22: "Merkur Gaming",
    23: "Novomatic",
    24: "Arrow's Edge",
    25: "Bally Wulff",
    27: "Real Time Gaming",
    28: "Pragmatic Play",
    40: "Felt Gaming",
    41: "MultiSlot",
    42: "Barcrest Games",
    43: "Playson",
    44: "JoinGames",
    45: "Pariplay",
    46: "Quickfire",
    47: "MrSlotty",
    49: "2By2",
    50: "Genii",
    51: "Endorphina",
    52: "Habanero",
    53: "Blueprint Gaming",
    54: "Bovada",
    55: "Red Tiger",
    56: "Yggdrasil Gaming",
    57: "SG Gaming",
    58: "Amaya",
    60: "Thunderkick",
    61: "Spinomenal",
    62: "Booming Games",
    63: "Tom Horn Gaming",
    64: "Greentube",
    66: "Probability Jones",
    67: "Magic Dreams",
    68: "Sigma Gaming",
    69: "Lightning Box Games",
    70: "Spieldev",
    71: "Storm Gaming Technology",
    72: "Games Warehouse",
    73: "SUNFOX Games",
    75: "Gamomat",
    76: "Capecod Gaming",
    77: "Beefee",
    78: "Blazesoft",
    79: "Eyecon",
    80: "Fugaso",
    81: "GameArt",
    82: "Kalamba Games",
    83: "Mascot Gaming",
    84: "KA Gaming",
    85: "Inspired Gaming",
    86: "Wazdan",
    87: "Stake Logic",
    88: "Oryx Gaming",
    89: "Red Rake Gaming",
    92: "AdoptIt Publishing",
    93: "AGames",
    94: "Ainsworth",
    95: "Air Dice",
    98: "Altea Gaming",
    99: "Amatic Industries",
    100: "Amazing Gaming",
    102: "Ash Gaming",
    103: "Aspect Gaming",
    104: "Asylum Labs",
    105: "Atronic",
    106: "August Gaming",
    110: "Bede Gaming",
    111: "Belatra Games",
    112: "BetConstruct",
    113: "Betdigital",
    114: "Betixon",
    115: "Betsense Games",
    116: "BGaming",
    117: "Big Time Gaming",
    118: "Big Wave Gaming",
    120: "Boomba Games",
    121: "Booongo",
    122: "Cadillac Jack",
    123: "Casino Technologies",
    124: "Casino Web Scripts",
    125: "Cayetano Gaming",
    126: "Chance Interactive",
    128: "Core Gaming",
    129: "Cozy Games",
    130: "CQ9 Gaming",
    131: "Crazy Tooth Studio",
    132: "Digitain",
    133: "DLV",
    134: "Dreamtech",
    135: "Easter Island Studio",
    136: "e-gaming",
    137: "EGT Interactive",
    138: "Electric Elephant",
    140: "Endemol Shine Gaming",
    141: "Espresso Games",
    142: "Everi",
    143: "EvoPlay Entertainment",
    144: "Eye Motion",
    145: "Fantasma Games",
    146: "Fazi",
    147: "Felix Gaming",
    148: "Foxium",
    150: "Fuga Gaming Technologies",
    151: "Game360",
    152: "Gameplay Interactive",
    153: "Games Lab",
    155: "Gamescale",
    156: "GamesOS",
    157: "Gamesys",
    158: "Gamevy",
    160: "GAMING1",
    161: "GamingSoft",
    162: "Ganapati",
    163: "Gaz Gaming",
    164: "GECO Gaming",
    166: "Gig Games",
    167: "Gioca Online",
    168: "High 5 Games",
    169: "Holland Power Gaming",
    171: "Igrosoft",
    173: "Incredible Technologies",
    174: "Ingenuity Gaming",
    177: "Intervision Gaming",
    178: "Just For The Win",
    179: "Kajot Games",
    180: "Konami Gaming",
    182: "Lionline",
    183: "Live 5 Gaming",
    186: "Makitone Gaming",
    187: "Mazooma Interactive Games",
    190: "MGA",
    191: "Mobilots",
    192: "Monarc Gaming Labs",
    193: "Iron Dog Studio",
    194: "Nektan",
    197: "Nolimit city",
    198: "Northern Lights Gaming",
    199: "Nucleus Gaming",
    201: "Octavian Gaming",
    202: "Octopus Gaming",
    203: "Old Skool Studios",
    204: "OMI Gaming",
    205: "OpenBet",
    206: "Paf",
    208: "Plank Gaming",
    211: "PlayPearls",
    212: "Playstar",
    213: "Pocket Games",
    214: "Portomaso Gaming",
    215: "Probability Gaming",
    216: "Proprietary Games",
    217: "Push Gaming",
    218: "R. Franco Games",
    219: "Rabcat",
    220: "RCT Gaming",
    221: "Realistic",
    222: "Red7",
    223: "Reel Time Gaming",
    224: "ReelNRG",
    225: "Reflex Gaming",
    226: "Relax Gaming",
    227: "Revolver Gaming",
    228: "SA Gaming",
    229: "Saber Interactive",
    230: "Saucify",
    231: "SHFL Entertainment",
    232: "Side City Studios",
    235: "Skillonnet",
    236: "Skillzzgaming",
    237: "Skywind",
    238: "Slotmotion",
    239: "Spadegaming",
    240: "Spielo",
    241: "SpiGo",
    242: "Spike Games",
    243: "SpinFan",
    244: "Spin Games",
    245: "Spinmatic",
    246: "STHLMGAMING",
    247: "Synot Games",
    248: "Tain",
    249: "The Art of Games",
    250: "The Games Company",
    251: "Toptrend Gaming",
    252: "Tuko Productions",
    254: "Unicum",
    256: "WeAreCasino",
    257: "WGS",
    258: "Whow Games",
    259: "Wild Streak Gaming",
    260: "WMG",
    261: "World Match",
    262: "Xplosive Slots",
    263: "Yoyoplay",
    265: "Zeus Play",
    270: "NetGame Entertainment",
    271: "Triple Edge Studios",
    275: "BF Games",
    282: "Imagina Gaming",
    285: "ReelPlay",
    288: "Promatic Games",
    293: "SmartSoft Gaming",
    294: "4Theplayer",
    295: "SlotVision",
    299: "PG Soft",
    300: "Edict",
    312: "FremantleMedia",
    314: "Noble Gaming",
    323: "InBet",
    326: "Golden Hero",
    336: "Xatronic",
    337: "TrueLab Games",
    347: "GMW",
    350: "Swintt",
    353: "Bet2Tech",
    354: "SimplePlay",
    357: "Hacksaw Gaming",
    358: "Caleta Gaming",
    360: "Bunfox",
    375: "OneTouch",
    391: "Intouch Games",
    393: "FBM Gaming",
    418: "Zitro",
    437: "Grand Vision Gaming",
    446: "Wiener Games Studio",
    453: "Apollo Games",
    466: "Slingo Originals",
    474: "Green Jade Games",
    488: "CEGO",
    497: "AGS",
    501: "Pirates Gold Studios",
    504: "Playzido",
    505: "BB Games",
    523: "NetGaming",
    525: "Aiwin Games",
    536: "Slot Factory",
    543: "Ruby Play",
    544: "FunFair Technologies",
    551: "Maverick",
    578: "7Mojos",
    582: "Charismatic Games",
    588: "GetBet",
    590: "Jade Rabbit Studio",
    594: "Nazionale Elettronica",
    606: "Spearhead Studios",
    607: "Epic Industries",
    608: "Woohoo Games",
    609: "Vibra Gaming",
    610: "EA Gaming",
    612: "Dragoon Soft",
    613: "Hölle Games",
    614: "Dragon Gaming",
    615: "Arcadem",
    616: "Wizard Games",
    617: "Gamzix",
  },
}

export default arcadeConditions
