import React from "react"
import { graphql } from "gatsby"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"
import GameCollection from "~/models/game-collection"

import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import CollapsibleListBlock from "~/ui/compositions/generic/collapsible-list"
import PageCoverBlock from "~/ui/compositions/generic/page-cover"
import ArcadeBlock from "~/ui/compositions/game/arcade"
import RandomGameBlock from "~/ui/compositions/game/random"
import GameListBlock from "~/ui/compositions/game/list"
import GameToplistBlock from "~/ui/compositions/game/toplist"
import ProviderListBlock from "~/ui/compositions/provider/list"
import TableBlock from "~/ui/compositions/generic/table"
import CardGridBlock from "~/ui/compositions/generic/card-grid"

import {
  FreeGamesQuery,
  ContentfulPage,
  ContentfulGameCollection,
} from "../../../types/graphql-types"

type Props = {
  data: FreeGamesQuery
}

function FreeGamesPage({ data }: Props) {
  const Analytics = Loadable(() => import("../helpers/analytics"))
  const page = new Page(data.contentfulPage as ContentfulPage)
  const gameCollections = data.allContentfulGameCollection.nodes.map(
    collection => {
      return new GameCollection(collection as ContentfulGameCollection)
    }
  )

  return (
    <Frame>
      <Analytics page={page} />
      <React.Fragment>
        <SEO page={page} />
        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "PageCover" && (
              <React.Fragment>
                <PageCoverBlock color="blue" {...block.props} />
                <div className="mb-2 mt-2">
                  <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
                    <Breadcrumbs breadcrumbs={page.breadcrumbs} />
                  </div>
                </div>
              </React.Fragment>
            )}
            {block.name == "Arcade" && <ArcadeBlock {...block.props} />}
            {block.name == "CardGrid" && <CardGridBlock {...block.props} />}
            {block.name == "RandomGame" && <RandomGameBlock {...block.props} />}
            {block.name == "GameList" && (
              <GameListBlock
                collection={gameCollections.find(
                  collection => collection.type == block.props.type
                )}
                {...block.props}
              />
            )}
            {block.name == "GameToplist" && (
              <GameToplistBlock
                collection={gameCollections.find(
                  collection => collection.type == "toplist"
                )}
                {...block.props}
              />
            )}
            {block.name == "Providers" && (
              <ProviderListBlock {...block.props} />
            )}
            {block.name == "Table" && <TableBlock {...block.props} />}
            {block.name == "CollapsibleList" && (
              <CollapsibleListBlock {...block.props} />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default FreeGamesPage

export const query = graphql`
  query FreeGames {
    contentfulPage(url: { pathname: { eq: "/free-games/" } }) {
      ...PageFields
    }
    allContentfulGameCollection(
      filter: { type: { in: ["all", "toplist", "teen-patti", "rummy"] } }
    ) {
      nodes {
        ...GameCollectionFields
      }
    }
  }
`
