import React from "react"

import TitledSection from "~/ui/layout/titled-section"

type ItemProps = {
  name: string
  image: string
}

type Props = {
  title: string
  items: ItemProps[]
  className?: string
}

function ProviderList({ title, items, className }: Props) {
  return (
    <TitledSection title={title} background="white" className={className}>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-10">
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className="bg-[#F9E493] p-6 flex justify-center rounded-md"
            >
              <img
                src={`/images/${item.image}`}
                alt=""
                className="h-[30px]"
                decoding="async"
              />
            </div>
          )
        })}
      </div>
    </TitledSection>
  )
}

export default ProviderList
